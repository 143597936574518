import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";

class ModalAdd extends Component {
  state = {};

  onChangeAudioFile = (e) => {
    console.log(e.target.files[0]);
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showAddModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.props.onFormSubmitNewReport}>
            <Modal.Header closeButton>
              <Form.Control
                required
                type="text"
                name="reportName"
                className="input-text-report-name"
                placeholder="Name your report"
              />
            </Modal.Header>
            <Modal.Body>
              <div style={{ fontSize: "13px", fontWeight: "500" }}>
                <Row>
                  <Col md={12}>
                    <div>
                      <Form.Group controlId="formSubmit">
                        <Form.Label>Choose Report File</Form.Label>
                        <Form.Control
                          onChange={this.props.onChangeReportFile}
                          required
                          type="file"
                          name="reportFile"
                          placeholder="Choose Reports File"
                        />
                      </Form.Group>
                    </div>
                    {this.props.fileError !== null && <div style={{ color: "red" }}>{this.props.fileError}</div>}

                    <div style={{ marginTop: "20px" }}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://sellercentral.amazon.com/reportcentral/INVENTORY_HEALTH/1"
                      >
                        Download your Inventory Health Report as a .txt file and upload it here
                      </a>
                    </div>

                    {/* <Row>
                      <Col md={4}>
                        <Form.Group controlId="formUsedSlot">
                          <Form.Label>Rank {">"}</Form.Label>
                          <Form.Control required as="select" name="minRank">
                            <option value="100000">100K</option>
                            <option value="200000">200K</option>
                            <option value="500000">500K</option>
                            <option value="1000000">1M</option>
                            <option value="2000000">2M</option>
                            <option value="3000000">3M</option>
                            <option value="5000000">5M</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group controlId="formUsedSlot">
                          <Form.Label>Lowest MF used price {"<"}</Form.Label>
                          <Form.Control required as="select" name="maxUsedPrice">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group controlId="formUsedSlot">
                          <Form.Label>Lowest FBA used price {"<"}</Form.Label>
                          <Form.Control required as="select" name="maxFBAUsedPrice">
                            <option value="-1">SKIP</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>
              </div>
            </Modal.Body>

            <Modal.Footer>
              {this.props.isUploading === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Add
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalAdd;
