import React, { Component } from 'react';
import { Row, Col, Form, Modal, Button, Spinner } from 'react-bootstrap';

class ModalDownload extends Component {
  state = {};

  onChangeAudioFile = (e) => {
    console.log(e.target.files[0]);
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showDownloadModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.props.onFormSubmitDownloadFile}>
            <Modal.Header closeButton>
              <h3>Download Removal/Disposal File</h3>
            </Modal.Header>
            <Modal.Body>
              <div style={{ fontSize: '13px', fontWeight: '500' }}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formUsedSlot">
                      <Form.Label>Removal or Disposal</Form.Label>
                      <Form.Control required as="select" name="rem_or_dep">
                        <option value="return">Removal</option>
                        <option value="disposal">Disposal</option>
                        <option value="liquidate">Liquidation</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formUsedSlot">
                      <Form.Label>Your Name</Form.Label>
                      <Form.Control
                        defaultValue={this.props.adminData.last_used_address_name}
                        required
                        type="text"
                        name="address_name"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div>
                  <Form.Group controlId="formUsedSlot">
                    <Form.Label>Address Field 1</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      defaultValue={this.props.adminData.last_used_address_field_one}
                      name="address_field_one"
                    />
                  </Form.Group>
                </div>

                <div>
                  <Form.Group controlId="formUsedSlot">
                    <Form.Label>Address Field 2</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={this.props.adminData.last_used_address_field_two}
                      name="address_field_two"
                    />
                  </Form.Group>
                </div>

                <div>
                  <Form.Group controlId="formUsedSlot">
                    <Form.Label>Address Field 3</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={this.props.adminData.last_used_address_field_three}
                      name="address_field_three"
                    />
                  </Form.Group>
                </div>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formUsedSlot">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        defaultValue={this.props.adminData.last_used_address_city}
                        name="address_city"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formUsedSlot">
                      <Form.Label>Country Code (2-digit abbreviation)</Form.Label>
                      <Form.Control required type="text" defaultValue={'US'} name="address_country_code" />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formUsedSlot">
                      <Form.Label>State or Region (2-digit abbreviation)</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        defaultValue={this.props.adminData.last_used_address_state}
                        name="address_state"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formUsedSlot">
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        defaultValue={this.props.adminData.last_used_address_postal_code}
                        name="address_postal_code"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formUsedSlot">
                      <Form.Label>Contact Phone Number (10 digits only)</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        defaultValue={this.props.adminData.last_used_contact_phone}
                        name="contact_phone"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formUsedSlot">
                      <Form.Label>Shipping Notes</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={this.props.adminData.last_used_shipping_notes}
                        name="shipping_notes"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>

            <Modal.Footer>
              {this.props.isDownloading === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Download
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalDownload;
