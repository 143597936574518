import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Route404 from "./pages/Route404";
import "./assets/style/theme_minimal.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import Report from "./pages/Report";
import Signup from "./pages/Signup";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <ProtectedRoute exact path="/report/:id" component={Report} />
            <ProtectedRoute exact path="/home" component={Home} />
            <Route component={Route404} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
