import React, { Component, createRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import TopMenu from '../components/TopMenu';
import auth from '../Auth';
import { callApi, submitNewReport } from '../includes/Helper';
import { ReactTabulator } from 'react-tabulator';
import ModalAdd from '../components/Reports/ModalAdd';

//import "react-tabulator/lib/styles.css"; // default theme
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css'; // use Theme(s)
// import { reactFormatter } from "../includes/TableFormatter.tsx";
import 'chart.js';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
moment().format();

const tableRef = createRef();

// function DownloadButton() {
//   return (
//     <div style={{ paddingRight: "20px" }}>
//       <i className="fa fa-download" />
//     </div>
//   );
// }

class Home extends Component {
  state = {
    admin_data: auth.getAdminData(),
    showAddModal: false,
    showDownloadModal: false,
    downloadingReportId: 0,
    reportName: '',
    currentFile: null,
    isUploading: false,
    isDownloading: false,
    fileError: null,
    reports: [],
  };

  constructor() {
    super();
    this.onFormSubmitNewReport = this.onFormSubmitNewReport.bind(this);
    this.onFormSubmitDownloadFile = this.onFormSubmitDownloadFile.bind(this);
    this.handleOpenReport = this.handleOpenReport.bind(this);
  }

  handleLogout() {
    auth.logout(() => {
      this.props.history.push('/');
    });
  }

  onChangeReportFile = (e) => {
    this.setState({ fileError: null });
    if (e.target.files[0] !== undefined) {
      if (e.target.files[0].name.substr(e.target.files[0].name.length - 3) !== 'txt')
        this.setState({ fileError: 'Invalid File. Please select a .txt file' });
      else this.setState({ currentFile: e.target.files[0] });
    }
  };

  async onFormSubmitDownloadFile(evt) {
    evt.preventDefault();
    this.setState({ isDownloading: true });

    callApi(
      `reports/download/${this.state.downloadingReportId}`,
      {
        user_id: this.state.admin_data.id,
        app_token: this.state.admin_data.token,
        address_name: evt.target.elements.address_name.value,
        address_field_one: evt.target.elements.address_field_one.value,
        address_field_two: evt.target.elements.address_field_two.value,
        address_field_three: evt.target.elements.address_field_three.value,
        address_city: evt.target.elements.address_city.value,
        address_country_code: evt.target.elements.address_country_code.value,
        address_state: evt.target.elements.address_state.value,
        address_postal_code: evt.target.elements.address_postal_code.value,
        contact_phone: evt.target.elements.contact_phone.value,
        shipping_notes: evt.target.elements.shipping_notes.value,
      },
      (response) => {
        if ('status' in response && response.status) {
          console.log(response);
          // updating last used user data
          this.setState({ admin_data: response.user });
          auth.setAdminData(response.user);

          // downloading csv
          let csvContent = 'data:text/csv;charset=utf-8,';
          csvContent += 'RemovalDisposition\treturn\r\n';
          csvContent += `AddressName\t${response.user.last_used_address_name}\r\n`;
          csvContent += `AddressFieldOne\t${response.user.last_used_address_field_one}\r\n`;
          csvContent += `AddressFieldTwo\t${response.user.last_used_address_field_two}\r\n`;
          csvContent += `AddressFieldThree\t${response.user.last_used_address_field_three}\r\n`;
          csvContent += `AddressCity\t${response.user.last_used_address_city}\r\n`;
          csvContent += `AddressCountryCode\t${response.user.last_used_address_country_code}\r\n`;
          csvContent += `AddressStateOrRegion\t${response.user.last_used_address_state}\r\n`;
          csvContent += `AddressPostalCode\t${response.user.last_used_address_postal_code}\r\n`;
          csvContent += `ContactPhoneNumber\t${response.user.last_used_contact_phone}\r\n`;
          csvContent += `ShippingNotes\t${response.user.last_used_shipping_notes}\r\n\r\n`;

          csvContent += `MerchantSKU\r\n`;

          for (let i = 0; i < response.data.length; i++) {
            csvContent += `${response.data[i][0]}\r\n`;
          }

          var encodedUri = encodeURI(csvContent);
          const fixedEncodedURI = encodedUri.replaceAll('#', '%23');
          var link = document.createElement('a');
          link.setAttribute('href', fixedEncodedURI);
          link.setAttribute('download', `report_${this.state.downloadingReportId}.txt`);
          document.body.appendChild(link); // Required for FF

          link.click(); // This will download the data file named "my_data.csv".
        }
        this.setState({ showDownloadModal: false, isDownloading: false });
      }
    );
  }
  async onFormSubmitNewReport(evt) {
    console.log('Sending new report to server for upload');
    evt.preventDefault();

    const reportName = evt.target.elements.reportName.value;
    // const minRank = evt.target.elements.minRank.value;
    // const maxUsedPrice = evt.target.elements.maxUsedPrice.value;
    // const maxFBAUsedPrice = evt.target.elements.maxFBAUsedPrice.value;
    const reportFile = this.state.currentFile;

    if (reportFile !== null && reportFile !== undefined) {
      this.setState({ isUploading: true });
      // Call API to add trigger
      const response = await submitNewReport(reportName, reportFile, this.state.admin_data);
      response !== false && response.status === 'success' && this.loadReports();
      this.setState({
        showAddModal: false,
        isUploading: false,
      });
    } else alert(`No file selected`);
  }

  componentDidMount() {
    this.loadReports();
  }

  loadReports() {
    callApi(
      'reports/list ',
      {
        user_id: this.state.admin_data.id,
        app_token: this.state.admin_data.token,
      },
      (response) => {
        if ('status' in response && response.status) this.setState({ reports: response.data });
      }
    );
  }

  handleOpenReport(rowId, reportName) {
    this.props.history.push({ pathname: `report/${rowId}`, state: { reportName: reportName } });
  }
  handleDownloadReport(rowData) {
    this.setState({ showDownloadModal: true, downloadingReportId: rowData.id });
  }

  render() {
    const editableColumns = [
      {
        title: 'ID',
        field: 'id',
        width: 65,
        headerSortStartingDir: 'desc',
      },
      {
        title: 'Name',
        field: 'name',
      },
      {
        title: 'Items',
        field: 'total_items',
        width: 95,
      },
      {
        title: 'Date Queued',
        field: 'added_on',
        sorter: 'date',
        sorterParams: { format: 'YYYY-MM-DD' },
        editorParams: { format: 'YYYY-MM-DD' },
      },
    ];

    return (
      <React.Fragment>
        <TopMenu adminData={this.state.admin_data} history={this.props.history} activeKey="/" />

        <ModalAdd
          fileError={this.state.fileError}
          showAddModal={this.state.showAddModal}
          isUploading={this.state.isUploading}
          onChangeReportFile={this.onChangeReportFile}
          handleClose={() => this.setState({ showAddModal: false })}
          onFormSubmitNewReport={this.onFormSubmitNewReport}
        />

        <Container fluid={true} className="page-dashboard">
          <Row>
            <Col md={12}>
              <div
                style={{
                  background: '#F8CC50',
                  color: '#000',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '500',
                  minHeight: '50px',
                  padding: '10px',
                  margin: '10px',
                  borderRadius: 10,
                }}
              >
                IMPORTANT NOTICE: Following recent changes at Amazon, the Inventory Health Report is no longer available
                in Seller Central. We're working on figuring out an alternate solution. Unfortunately, you won't be able
                to use this tool for now. Please check again in a few days. Sorry for any inconvenience caused.
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col md={8}>
              <div>
                <h3>Reports</h3>
              </div>
            </Col>
            <Col md={4}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  className="actionButtonLessPadding"
                  style={{ marginLeft: '8px' }}
                  variant="success"
                  onClick={() => this.setState({ showAddModal: true })}
                >
                  Add New
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div
                style={{
                  marginTop: '10px',
                  borderTop: '1px solid #ddd',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=dDg2ObHuJC0">
                  Click here to see how to use this free tool.
                </a>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
              <ReactTabulator
                ref={tableRef}
                cellClick={(e, cell) => {
                  console.log(cell);
                  if (cell._cell.column.field === 'custom') {
                    e.stopPropagation();
                    this.handleDownloadReport(cell._cell.row.data);
                  }
                  //console.log(cell._cell.column.field);
                }}
                rowClick={(e, row) => {
                  console.log(e);
                  const reportId = row._row.data.id;
                  const reportName = row._row.data.name;
                  this.handleOpenReport(reportId, reportName);
                }}
                // cellEdited={(cell) => {
                //   //const row = cell.getRow();
                //   // console.log(row);
                //   // console.log("cellEdited", cell);

                //   const oldValue = cell._cell.oldValue;
                //   const newValue = cell._cell.value;
                //   const fieldName = cell._cell.column.field;
                //   const mpSourceId = cell._cell.row.data.id;
                //   console.log({ oldValue, newValue, fieldName, mpSourceId });

                //   this.handleUpdateReportMeta({ oldValue, newValue, fieldName, mpSourceId });
                // }}
                columns={editableColumns}
                data={this.state.reports}
                // footerElement={<span>Footer</span>}
                initialSort={[{ column: 'id', dir: 'desc' }]}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default Home;
