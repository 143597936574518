export const domainName = window.location.hostname;
const switchDomain = 2;
export const siteColors = [
  "#FFFFFF",
  "#00743F",
  "#F4874B",
  "#6A8A82",
  "#A37C27",
  "#72A2C0",
  "#0D3C55",
  "#1ABC9C",
  "#040C0E",
  "#0444BF",
  "#A7414A",
  "#6465A5",
  "#F20050",
  "#2196F3",
  "#009688",
];

export const apiBaseUrlLocal =
  process.env.NODE_ENV === "development"
    ? switchDomain === 1
      ? "http://localhost:5002/v1/"
      : "https://api-restock.thebookflipper.com/v1/"
    : "https://api-restock.thebookflipper.com/v1/";
